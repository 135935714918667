<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <b-card
          text-variant="center"
          class="card card-congratulations"
        >
          <b-avatar
            :src="require('@/assets/images/avatars/13-small.png')"
            size="70"
            class="shadow mb-2"
          >
          </b-avatar>
          <h3 class="mb-1 mt-50 text-white">
            Halo {{ userData.name }} 🖐️
          </h3>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      data: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Siswa',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        // Add more items as needed
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>

<style>

</style>
